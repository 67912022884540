import React from 'react';
import logotype from '../assets/logotype.png'
import work from '../assets/work.svg'


const Main = () => {
    return (
        <div className="main-section">
            <div className="main-section__container">
                <img className="container__logotype" src={logotype} />
                <div className="container__text">
                    <h1>Благодарим Вас за отклик!</h1>
                    <p>Для записи на собеседование, пожалуйста, выберите вакансию и заполните короткую онлайн-анкету, нажав на кнопку ниже</p>
                </div>
                <a target="_blank" href="https://findcareer.ru/" className="link"><img src={work} />Перейти к вакансиям</a>
                <div className="container__questions">
                    <p>Остались вопросы? </p>
                    <a href="mailto:findcareer@mail.ru"> Свяжитесь с нами!</a>
                </div>
            </div>
        </div>
    );
}

export default Main